<template>
  <div class="About-root">
    <div class="About-banner-wrapper">
      <div class="About-banner">
        <div class="text">
          <div class="en">ABOUT CHE YINTONG</div>
          <div class="zh">关于车银通</div>
          <div class="small">致力于成为二手车库存融资业务最佳解决方案提供商</div>
        </div>
      </div>
    </div>
    <div class="About-content1">
      <div class="About-content1-container">
        <div class="section-header">
          <div class="section-header-subtitle">COMPANY PROFILE</div>
          <div class="section-header-title">公司简介</div>
          <div class="section-header-line"></div>
        </div>
        <div class="section-body">
          <div>
            北京车银通二手车鉴定评估有限公司，正式成立于2012年7月，是北京车车网络技术有限公司旗下控股子公司。是经商务部批准、北京市工商局备案，具有独立性、权威性、专业性的二手车鉴定评估机构。公司是基于海量车源交易数据，通过数据清洗、人工智能、二手车残值模型、机器学习模型训练等技术或数据为依托提供二手车鉴定评估服务，公司秉承公平、公正、科学、统一、高效的原则，严格按照检测程序和评估标准，组织和实施鉴定旧机动车评估，所出具的鉴定评估报告具有法律效力。
          </div>
          <br />
          <div>
            车银通一直以来深耕二手车库存融资业务，做为中立的第三方二手车评估服务平台，为整个二手车库存融资业务交易环节的风险控制而效力，为金融机构和融资客户提供专业的二手车评估、全流程风控和业务运营服务解决方案。
          </div>
        </div>
      </div>
    </div>
    <div class="About-content2">
      <div class="About-content2-container">
        <div class="section-header">
          <div class="section-header-subtitle">SERVICE OBJECT</div>
          <div class="section-header-title">服务对象</div>
          <div class="section-header-line"></div>
        </div>
        <div class="section-body">
          <div>
            <img src="./assets/组 2707@2x.png" />
            <div class="text">金融机构</div>
          </div>
          <div>
            <img src="./assets/组 2708@2x.png" />
            <div class="text">主机厂商</div>
          </div>
          <div>
            <img src="./assets/组 2709@2x.png" />
            <div class="text">经销商集团</div>
          </div>
          <div>
            <img src="./assets/组 2710@2x.png" />
            <div class="text">保险机构</div>
          </div>
          <div>
            <img src="./assets/组 2711@2x.png" />
            <div class="text">融资租赁机构</div>
          </div>
          <div>
            <img src="./assets/组 2712@2x.png" />
            <div class="text">二手车交易市场</div>
          </div>
          <div>
            <img src="./assets/组 2715@2x.png" />
            <div class="text">二手车关联科技公司</div>
          </div>
          <div>
            <img src="./assets/组 2714@2x.png" />
            <div class="text">个人消费者</div>
          </div>
        </div>
      </div>
    </div>
    <div class="About-content3">
      <div class="About-content3-container">
        <div class="section-header">
          <div class="section-header-subtitle">SERVICE CONTENT</div>
          <div class="section-header-title">服务内容</div>
          <div class="section-header-line"></div>
        </div>
        <div class="section-body">
          <div>
            <img src="./assets/01@2x.png" />
            <div class="title">评估服务</div>
            <div class="text">
              运用大数据、车辆残值数据算法、学习型人工智能等技术，建立精确、透明、快速的二手车残值估值体系。采用科学的数据挖掘与机器学习方法和理论，为二手车融资租赁、二手车金融、二手车交易服务商等提供安全的、专业的、高效的二手车评估服务解决方案。
            </div>
          </div>
          <div>
            <img src="./assets/02@2x.png" />
            <div class="title">系统服务</div>
            <div class="text">
              自主研发“车银融通”二手车库存融资业务管理系统，支持二手车库存融资全链条业务管理。为合作机构提供一站式二手车鉴定、检测、残值评估服务，通过业务系统的应用，可以数据化、标准化、便捷化管理车辆库存融资过程中的各环节业务数据，致力于为金融机构提供二手车库存融资业务及二手车风险控制解决方案。
            </div>
          </div>
          <div>
            <img src="./assets/03@2x.png" />
            <div class="title">运营服务</div>
            <div class="text">
              二手车业务与新车业务不同，二手车是非标准化产品，一车一况一手续等特殊性，导致二手车业务很难向规模化、平台化发展，金融机构放款规模也受很大制约。为了解决以上问题，助力合作金融机构二手车业务形成规模效应，根据二手车融资业务特点，在二手车融资业务过程中，将非标准化的二手车产品，按照业务流程阶段，打造一个标准化业务流程，在业务不同阶段配置专业的运营服务团队提供业务支持服务。
            </div>
          </div>
          <div>
            <img src="./assets/04@2x.png" />
            <div class="title">数据服务</div>
            <div class="text">
              通过长期稳定汇聚汽车车源真实数据，建立车辆数据信息服务中心，通过大数据分析、建立车辆残值机器学习评估模型算法，结合车源交易数据，通过不断的机器学习来训练评估模型的精准度。目前车源数据信息服务中心对外开放的服务包含：车型数据库、二手车残值估值、专家精准定价、VIN识别车型、车辆残值率、维修保养报告、碰撞出险报告及其他定制api服务等。
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="About-content4">
      <div class="About-content4-container">
        <div class="section-header">
          <div class="section-header-subtitle">SERVICE GUARANTEE</div>
          <div class="section-header-title">服务保障</div>
          <div class="section-header-line"></div>
        </div>
        <div class="section-title">
          北京车银通二手车鉴定评估有限公司搭建了强大的运营服务团队，可以为客户提供高效稳定可靠的标准化服务，帮助客户实现对二手车库存融资业务的有效管理。也可以按照客户要求定制配备专业的服务团队。
        </div>
        <div class="section-body">
          <div>
            <img src="./assets/组 2731@2x.png" />
            <div class="title">项目经理</div>
            <div class="text">日常沟通协调、客户需求采集、项目管理和业务流程跟进</div>
          </div>
          <div>
            <img src="./assets/组 2732@2x.png" />
            <div class="title">客服团队</div>
            <div class="text">上线准备、客户培训、车辆信息标准化管理，日常客服服务中心</div>
          </div>
          <div>
            <img src="./assets/组 2734@2x.png" />
            <div class="title">审核团队</div>
            <div class="text">车辆电子档案信息管理、评估车辆信息审核、预警信息通知、数据统计、数据报告</div>
          </div>
        </div>
      </div>
    </div>
    <div class="About-content5">
      <div class="About-content5-container">
        <div class="section-header">
          <div class="section-header-subtitle">CORE IDEA</div>
          <div class="section-header-title">核心理念</div>
          <div class="section-header-line"></div>
        </div>
        <div class="section-body">
          <div>
            <div class="title">ENTERPRISE VISION</div>
            <div class="title">企业愿景</div>
            <div class="line"></div>
            <div class="text">致力于成为二手车库存融资业务最佳解决方案和数据服务提供商</div>
          </div>
          <div>
            <div class="title">ENTERPRISE MISSION</div>
            <div class="title">企业使命</div>
            <div class="line"></div>
            <div class="text">坚定做中立的第三方二手车鉴定评估机构，极致保障客户资产更安全</div>
          </div>
          <div>
            <div class="title">MANAGEMENT IDEA</div>
            <div class="title">经营理念</div>
            <div class="line"></div>
            <div class="text">以客户需求为导向，以客户价值为目标，以客户满意为标准</div>
          </div>
          <div>
            <div class="title">SERVICE&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; IDEA</div>
            <div class="title">服务理念</div>
            <div class="line"></div>
            <div class="text">客户至上，服务周到，快速响应，精益求精</div>
          </div>
        </div>
      </div>
    </div>
    <div class="About-content5-bg"></div>
    <div class="About-content6">
      <div class="About-content6-container">
        <div class="section-header">
          <div class="section-header-subtitle">CONTACT US</div>
          <div class="section-header-title">联系我们</div>
          <div class="section-header-line"></div>
        </div>
        <div class="section-body">
          <div>
            <img src="./assets/组 2716@2x.png" />
            <span class="phone">400-800-7107</span>
          </div>
          <div>
            <img src="./assets/组 2717@2x.png" />
            <span>bc@chechebj.com</span>
          </div>
          <div>
            <img src="./assets/组 2718@2x.png" />
            <span>北京市朝阳区东四环中路195号楼9层909-2</span>
          </div>
        </div>
        <div class="right">
          <amap :zoom="map.zoom" :center="map.center">
            <amap-marker :position="map.center" :label="{ content: '华腾新科技大厦', direction: 'bottom' }" />
          </amap>
        </div>
      </div>
    </div>
    <div class="About-content6-bg"></div>
  </div>
</template>
<script>
import './styles/mobile.scss';
import './styles/pc/pc.scss';
import Amap from '@amap/amap-vue/lib/amap';
import AmapMarker from '@amap/amap-vue/lib/marker';
export default {
  name: 'About',
  components: {
    Amap,
    AmapMarker,
  },
  data() {
    return {
      map: {
        center: [116.48873, 39.885513],
        zoom: 14,
      },
    };
  },
};
</script>
